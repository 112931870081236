import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { MultipleSelectFilter } from "@/components/Filters/MultipleSelectFilter";
import { PostalCodeFilter } from "@/components/Filters/PostalCodeFilter";
import { SearchFilter } from "@/components/Filters/SearchFilter";
import { VoivodeshipFilter } from "@/components/Filters/VoivodeshipFilter";
import { Combobox } from "@/components/forms/Combobox";
import { Select } from "@/components/forms/Select";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { AuditStatusContext } from "@/context/AuditStatusContext";
import { UsersContext } from "@/context/UsersContext";
import { appendEmptyOption } from "@/helpers/appendEmptyOption";
import { useFilters } from "@/hooks/table/useFilters";
import { useAuth } from "@/hooks/useAuth";
import { useAuditPolicy } from "@/policies/audit/useAuditPolicy";
import { useLeadPolicy } from "@/policies/lead/useLeadPolicy";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Pencil, User } from "lucide-react";
import { useContext, useEffect, useState } from "react";

export const AuditFilters = ({ table }) => {
  const { hasAnyPermission } = useAuth();
  const auditPolicy = useAuditPolicy();
  const leadPolicy = useLeadPolicy();
  const tableSelected = table.getSelectedRowModel()?.flatRows;
  const queryClient = useQueryClient();
  const { statusOptions } = useContext(AuditStatusContext);
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);
  const { filter, setFilter } = useFilters(table);

  const [showMassActions, setShowMassActions] = useState(false);
  const [leadOptions, setLeadOptions] = useState([]);
  const [assignedUserId, setAssignedUserId] = useState(undefined);

  const {
    isLoading: isLoadingLeads,
    data: leads,
    error: errorLeads,
  } = useQuery({
    queryKey: ["leads"],
    queryFn: () => apiClient.getLeads({ hasAudit: true }),
    staleTime: 1000 * 60 * 5,
    enabled: leadPolicy.viewAny() && auditPolicy.viewAll(),
  });

  const assignAuditsToUserMutation = useMutation({
    mutationFn: apiClient.assignAuditsToUser,
    onSuccess: (res) => {
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie przypisano kontakty do użytkownika" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
      queryClient.invalidateQueries({ queryKey: ["sidebar"] });
      queryClient.invalidateQueries({ queryKey: ["audits"] });
    },
  });

  const bulkEditStatusAuditsMutation = useMutation({
    mutationFn: apiClient.bulkEditStatusAudits,
    onSuccess: (res) => {
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zmieniono status" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
      queryClient.invalidateQueries({ queryKey: ["sidebar"] });
      queryClient.invalidateQueries({ queryKey: ["audits"] });
    },
  });

  const handleAssignAuditsToUser = (userId) => {
    if (tableSelected?.length > 0) {
      assignAuditsToUserMutation.mutate({ userId, auditIds: tableSelected.map((s) => s.id) });
    }
  };

  const handleBulkEditStatus = (auditStatusId) => {
    if (tableSelected?.length > 0) {
      bulkEditStatusAuditsMutation.mutate({
        auditIds: tableSelected.map((s) => s.id),
        auditStatusId,
      });
    }
  };

  useEffect(() => {
    if (!isLoadingLeads && !errorLeads && leads) {
      setLeadOptions(leads.data.map((user) => ({ name: user.name, value: user.id })));
    }
  }, [isLoadingLeads, leads, errorLeads]);

  useEffect(() => {
    if (assignAuditsToUserMutation.status === "success" || assignAuditsToUserMutation.status === "error") {
      const timeout = setTimeout(() => {
        setAssignedUserId(undefined);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [assignAuditsToUserMutation.status]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row flex-wrap gap-3 mb-3">
        <div className="flex flex-row gap-3 w-full lg:max-w-sm">
          <ClearFiltersButton table={table} />
          <SearchFilter value={filter("search")} onChange={(value) => setFilter("search", value)} />
        </div>
        <MultipleSelectFilter
          options={statusOptions}
          setValue={(value) => setFilter("status", value)}
          title="Status"
          value={filter("status")}
        />
        <VoivodeshipFilter filter={filter} setFilter={setFilter} />
        <PostalCodeFilter value={filter("postalCode")} onChange={(value) => setFilter("postalCode", value)} />
        {auditPolicy.viewAll() && (
          <MultipleSelectFilter
            options={appendEmptyOption(userOptions, "Nieprzypisane")}
            setValue={(value) => setFilter("user", value)}
            title="Użytkownik"
            isLoading={isLoadingUsers}
            value={filter("user")}
          />
        )}
        {leadPolicy.viewAny() && auditPolicy.viewAll() && (
          <MultipleSelectFilter
            options={leadOptions}
            setValue={(value) => setFilter("lead", value)}
            title="Kontakt"
            isLoading={isLoadingLeads}
            value={filter("lead")}
          />
        )}
        <ColumnFilter table={table} />
        {hasAnyPermission([
          "bulk_delete_audits",
          "bulk_unassign_audit_user",
          "bulk_unassign_audit_department",
          "bulk_edit_audit_status",
          "assign_user_audits",
          "assign_department_audits",
        ]) && (
          <Button
            variant="outline"
            title="Akcje masowe"
            className="w-full lg:w-fit"
            onClick={() => setShowMassActions(!showMassActions)}
          />
        )}
      </div>
      {showMassActions && (
        <div className="flex flex-row flex-wrap gap-3 mb-3">
          {auditPolicy.bulkEditStatus() && (
            <Select
              className="flex-1 lg:flex-initial w-fit font-medium"
              placeholder="Zmien status zaznaczonym"
              leftIcon={<Pencil size={16} className="mr-1" />}
              disabled={tableSelected.length === 0}
              options={statusOptions}
              setValue={handleBulkEditStatus}
              isLoading={bulkEditStatusAuditsMutation.isPending}
            />
          )}
          {auditPolicy.assignUserAudits() && (
            <Combobox
              placeholder="Przypisz do użytkownika"
              className="flex-1 lg:flex-initial lg:w-80 dark:text-foreground dark:bg-opacity-70 justify-center"
              variant="outline"
              leftIcon={<User size={16} />}
              options={userOptions}
              value={assignedUserId}
              disabled={tableSelected.length === 0}
              isLoading={isLoadingUsers || assignAuditsToUserMutation.isPending}
              setValue={handleAssignAuditsToUser}
            />
          )}
        </div>
      )}
    </div>
  );
};
