import { ApplicationStatusCreateDialog } from "@/components/Applications/Settings/Statuses/ApplicationStatusCreateDialog";
import { ApplicationStatuses } from "@/components/Applications/Settings/Statuses/ApplicationStatuses";
import { ApplicationTypeCreateDialog } from "@/components/Applications/Settings/Types/ApplicationTypeCreateDialog";
import { ApplicationTypes } from "@/components/Applications/Settings/Types/ApplicationTypes";
import { ProcessCreateDialog } from "@/components/Process/ProcessCreateDialog";
import { ProcessSettings } from "@/components/Process/ProcessSettings";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import i18n from "@/i18n";
import { useApplicationStatusPolicy } from "@/policies/application/useApplicationStatusPolicy";
import { useApplicationTypePolicy } from "@/policies/application/useApplicationTypePolicy";
import { useProcessPolicy } from "@/policies/useProcessPolicy";
import { useState } from "react";

export const ApplicationSettings = () => {
  const [activeTab, setActiveTab] = useState("statuses");
  const applicationStatusPolicy = useApplicationStatusPolicy();
  const applicationTypePolicy = useApplicationTypePolicy();
  const processPolicy = useProcessPolicy();

  return (
    <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value)}>
      <TabsList className="mb-6">
        {applicationStatusPolicy.create() && <TabsTrigger value="statuses">{i18n.t("Statusy")}</TabsTrigger>}
        {applicationTypePolicy.create() && <TabsTrigger value="sources">{i18n.t("Typy")}</TabsTrigger>}
        {processPolicy.create() && <TabsTrigger value="processes">{i18n.t("Procesy")}</TabsTrigger>}
      </TabsList>
      {applicationStatusPolicy.create() && (
        <TabsContent value="statuses">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Statusy")}</h2>
            <ApplicationStatusCreateDialog />
          </div>
          <ApplicationStatuses />
        </TabsContent>
      )}
      {applicationTypePolicy.create() && (
        <TabsContent value="sources">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Typy wniosków")}</h2>
            <ApplicationTypeCreateDialog />
          </div>
          <ApplicationTypes />
        </TabsContent>
      )}
      {processPolicy.create() && (
        <TabsContent value="processes">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Procesy")}</h2>
            <ProcessCreateDialog appliesTo="Application" />
          </div>
          <ProcessSettings model="Application" />
        </TabsContent>
      )}
    </Tabs>
  );
};
