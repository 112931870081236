import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { InstallationCrewCreateDialog } from "@/components/Installations/Crews/InstallationCrewCreateDialog";
import { InstallationCrewsTable } from "@/components/Installations/Crews/InstallationCrewsTable";
import { InstallationCreateDialog } from "@/components/Installations/InstallationCreateDialog";
import { InstallationsCalendar } from "@/components/Installations/InstallationsCalendar";
import { InstallationsTable } from "@/components/Installations/InstallationsTable";
import { InstallationsServicesMap } from "@/components/Installations/Map/InstallationsServicesMap";
import { InstallationSettings } from "@/components/Installations/Settings/InstallationSettings";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import i18n from "@/i18n";
import { useInstallationCrewPolicy } from "@/policies/installation/useInstallationCrewPolicy";
import { useInstallationStatusPolicy } from "@/policies/installation/useInstallationStatusPolicy";
import { Map } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

export const InstallationsPage = () => {
  useDocumentTitle("Montaże");
  const { state } = useLocation();
  const installationStatusPolicy = useInstallationStatusPolicy();
  const installationCrewPolicy = useInstallationCrewPolicy();
  const bottomRef = useRef(null);

  const [activeTab, setActiveTab] = useState("installations");
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    if (state?.tab) {
      setActiveTab(state.tab);
    }
  }, [state]);

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {(activeTab === "installations" || activeTab === "services") && (
            <Button
              title="Pokaż mapę"
              variant="outline"
              leftIcon={<Map size={16} />}
              onClick={() => {
                setShowMap(!showMap);
                if (!showMap)
                  setTimeout(() => {
                    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
                  }, 200);
              }}
            />
          )}
          {activeTab === "installations" && <InstallationCreateDialog />}
          {activeTab === "crews" && <InstallationCrewCreateDialog />}
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value)}>
        {(installationStatusPolicy.create() || installationCrewPolicy.viewAny()) && (
          <TabsList className="mb-6">
            <TabsTrigger value="installations">{i18n.t("Montaże")}</TabsTrigger>
            {/* <TabsTrigger value="services">{i18n.t("Serwisy")}</TabsTrigger> */}
            <TabsTrigger value="calendar">{i18n.t("Kalendarz")}</TabsTrigger>
            {installationCrewPolicy.viewAny() && <TabsTrigger value="crews">{i18n.t("Ekipy montażowe")}</TabsTrigger>}
            {installationStatusPolicy.create() && <TabsTrigger value="settings">{i18n.t("Ustawienia")}</TabsTrigger>}
          </TabsList>
        )}
        <TabsContent value="installations">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <InstallationsTable installations={[]} />
          </div>
        </TabsContent>
        <TabsContent value="services">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <InstallationsTable />
          </div>
        </TabsContent>
        <TabsContent value="calendar">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <InstallationsCalendar />
          </div>
        </TabsContent>
        {installationCrewPolicy.viewAny() && (
          <TabsContent value="crews">
            <div className="flex flex-col lg:flex-row w-full gap-5">
              <InstallationCrewsTable />
            </div>
          </TabsContent>
        )}
        {installationStatusPolicy.create() && (
          <TabsContent value="settings">
            <InstallationSettings />
          </TabsContent>
        )}
      </Tabs>
      {showMap && (activeTab === "installations" || activeTab === "services") && (
        <InstallationsServicesMap installations={[]} />
      )}
      <div ref={bottomRef}></div>
    </Layout>
  );
};
