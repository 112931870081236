import { Button } from "@/components/Button";
import { Badge } from "@/components/ui/badge";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { Check } from "lucide-react";
import { useEffect, useState } from "react";

export function SelectFilter({ value, setValue, title, options, disabled = false, isLoading = false, ...rest }) {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    if (currentValue) setValue(currentValue);
  }, [currentValue]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn("flex-1 lg:flex-initial", currentValue && "border-primary")}
          disabled={disabled}
          isLoading={isLoading}
        >
          <>
            {title}
            {currentValue && (
              <>
                <Separator orientation="vertical" className="h-4" />
                <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                  {options.find((option) => option.value === currentValue)?.name}
                </Badge>
              </>
            )}
          </>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0" align="start" {...rest}>
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>{i18n.t("Brak wyników")}</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isSelected = currentValue === option.value;
                return (
                  <CommandItem
                    key={option.value}
                    onSelect={() => {
                      if (option.value === currentValue) {
                        setValue(undefined);
                        setCurrentValue(undefined);
                      } else {
                        setCurrentValue(option.value);
                        setValue(option.value);
                      }
                    }}
                  >
                    {isSelected && <Check className="mr-1 h-4 w-4" />}
                    {option.icon && <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
                    <span className={isSelected ? "" : "pl-5"}>{option.content ? option.content : option.name}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
