import { Button } from "@/components/Button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { CardRow } from "@/components/ui/CardRow";
import { UserTooltip } from "@/components/Users/UserTooltip";
import { ProductsContext } from "@/context/ProductsContext";
import { CalculatorFieldType } from "@/enums/CalculatorFieldTypeEnum";
import { LeadType } from "@/enums/LeadTypeEnum";
import i18n from "@/i18n";
import { useLeadPolicy } from "@/policies/lead/useLeadPolicy";
import { useCalculationPolicy } from "@/policies/useCalculationPolicy";
import { useCalculatorPolicy } from "@/policies/useCalculatorPolicy";
import { useProductPolicy } from "@/policies/useProductPolicy";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { routes } from "@/routes";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

export const CalculationFieldsSummary = ({ calculation }) => {
  const userPolicy = useUserPolicy();
  const productPolicy = useProductPolicy();
  const calculationPolicy = useCalculationPolicy();
  const calculatorPolicy = useCalculatorPolicy();
  const leadPolicy = useLeadPolicy();
  const { products } = useContext(ProductsContext);
  const navigate = useNavigate();
  const sections = (calculation?.calculator?.sections ?? []).filter((section) =>
    section.fields?.some((field) => !!field.value && field.value !== "null"),
  );

  const renderFieldRow = (field) => {
    let rowValue = "";
    let rowQuantity = null;
    if (!field.value || field.value === "null") return null;

    switch (field.type) {
      case CalculatorFieldType.LIST.value: {
        rowValue = field.value;
        break;
      }
      case CalculatorFieldType.PRODUCT.value: {
        const product = products.find((product) => product.id === field.value);
        rowValue = product?.name;
        break;
      }
      case CalculatorFieldType.MULTIPLE.value: {
        rowValue = field.value.join(", ");
        break;
      }
      case CalculatorFieldType.ADDITIONAL.value: {
        rowValue = field.value.map((val) => val.name).join(", ");
        break;
      }
      case CalculatorFieldType.NUMBER.value:
        rowValue = field.value;
        break;
      case CalculatorFieldType.VAT.value: {
        rowValue = field.value;
        break;
      }
      case CalculatorFieldType.COMISSION.value: {
        if (!calculationPolicy.view(calculation)) return;
        rowValue = field.value;
        break;
      }
      default:
        return null;
    }

    if (field.has_quantity && field.value && field.value !== "null") {
      rowQuantity = `Ilość: ${field.quantity}`;
    }

    return field.type === CalculatorFieldType.PRODUCT.value && productPolicy.view() ? (
      <CardRow key={field.id}>
        <p className="text-xs sm:text-base">{field.name}</p>
        <span className="flex flex-col">
          <Button
            variant="link"
            onClick={() => navigate(`${routes.products}/${field.value}`)}
            className="m-0 p-0 h-fit"
          >
            <p className="whitespace-pre-wrap text-right">{rowValue}</p>
          </Button>
          <p className="whitespace-pre-wrap text-right">{rowQuantity}</p>
        </span>
      </CardRow>
    ) : (
      <CardRow key={field.id}>
        <p className="text-xs sm:text-base">{field.name}</p>
        <span className="flex flex-col">
          <p className="whitespace-pre-wrap text-right">{rowValue}</p>
          <p className="whitespace-pre-wrap text-right">{rowQuantity}</p>
        </span>
      </CardRow>
    );
  };

  return (
    <>
      <Card className="w-full h-fit break-inside-avoid mb-5">
        <CardHeader>
          <CardTitle>{i18n.t("Informacje podstawowe")}</CardTitle>
          <CardDescription>{calculation.calculator.name}</CardDescription>
        </CardHeader>
        <CardContent className="divide-y divide-y-border">
          <CardRow>
            <p className="text-xs sm:text-base">{i18n.t("Kalkulator")}</p>
            {calculatorPolicy.viewAny() ? (
              <Link to={`${routes.calculators}/${calculation.calculator.id}`}>
                <Button variant="link" size="xs">
                  {calculation.calculator.name}
                </Button>
              </Link>
            ) : (
              <span>{calculation.calculator.name}</span>
            )}
          </CardRow>
          {calculation.lead && calculation.lead.type === LeadType.CLIENT ? (
            <CardRow>
              <p className="text-xs sm:text-base">{i18n.t("Klient")}</p>
              {leadPolicy.view() ? (
                <Link to={`${routes.clients}/${calculation.lead.id}`}>
                  <Button variant="link" size="xs">
                    {calculation.lead.name}
                  </Button>
                </Link>
              ) : (
                <span>{calculation.lead.name}</span>
              )}
            </CardRow>
          ) : (
            <CardRow>
              <p className="text-xs sm:text-base">{i18n.t("Kontakt")}</p>
              {leadPolicy.view() ? (
                <Link to={`${routes.leads}/${calculation.lead.id}`}>
                  <Button variant="link" size="xs">
                    {calculation.lead.name}
                  </Button>
                </Link>
              ) : (
                <span>{calculation.lead.name}</span>
              )}
            </CardRow>
          )}
          {calculationPolicy.view(calculation) && (
            <CardRow>
              <p className="text-xs sm:text-base">{i18n.t("Stworzona przez")}</p>
              {userPolicy.view(calculation?.user) && <UserTooltip user={calculation.user} />}
            </CardRow>
          )}
          <CardRow>
            <p className="text-xs sm:text-base">{i18n.t("Data utworzenia")}</p>
            <p>{format(parse(calculation.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "d MMMM yyyy")}</p>
          </CardRow>
        </CardContent>
      </Card>
      {sections.map((section) => (
        <Card key={section.id} className="w-full h-fit break-inside-avoid mb-5">
          <CardHeader>
            <CardTitle>{section.name}</CardTitle>
            <CardDescription>{section.description}</CardDescription>
          </CardHeader>
          <CardContent className="divide-y divide-y-border">
            {section.fields.map((field) => renderFieldRow(field))}
          </CardContent>
        </Card>
      ))}
    </>
  );
};
