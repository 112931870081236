import mapPin from "@/assets/icons/mapPin.svg";
import { InstallationPopup } from "@/components/Installations/Map/InstallationPopup";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

export const InstallationsServicesMap = ({ installations }) => {
  const customIcon = new Icon({
    iconUrl: mapPin,
    iconSize: [38, 38],
    iconAnchor: [19, 38],
    popupAnchor: [0, -19],
  });

  return (
    <MapContainer center={[53.482892, 18.749418]} zoom={13}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MarkerClusterGroup chunkedLoading>
        {installations.map((installation) => {
          if (installation?.contract?.address) {
            const { latitude, longitude } = installation.contract.address;
            if (!latitude || !longitude) return null;
            return (
              <Marker key={installation.id} position={[parseFloat(latitude), parseFloat(longitude)]} icon={customIcon}>
                <Popup maxWidth={500}>
                  <InstallationPopup installation={installation} />
                </Popup>
              </Marker>
            );
          }
        })}
      </MarkerClusterGroup>
    </MapContainer>
  );
};
