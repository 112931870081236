import { Button } from "@/components/Button";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import i18n from "@/i18n";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";
import { routes } from "@/routes";
import { Plus } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";

export const ClientContractsCard = ({ contracts, client }) => {
  const contractPolicy = useContractPolicy();
  const navigate = useNavigate();

  return (
    <Card className="flex-1 h-fit shadow-md pr-2">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{i18n.t("Umowy klienta")}</CardTitle>
          <CardDescription>{i18n.t("Lita wszystkich umów tego klienta")}</CardDescription>
        </div>
        {contractPolicy.create() && (
          <Button
            leftIcon={<Plus size={20} />}
            variant="outline"
            title="Dodaj umowę"
            onClick={() => navigate(routes.contractCreate, { state: { lead: client } })}
          />
        )}
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-3 justify-center items-center">
          {contracts?.map((contract) => {
            return (
              <Card
                key={contract.id}
                className="p-4 shadow-md border-l-4 w-full"
                style={{ borderLeftColor: contract.status.color ?? "hsl(var(--primary))" }}
              >
                <div className="flex flex-col justify-between items-start w-full gap-3">
                  <div className="flex flex-col gap-2 items-center sm:items-start w-full whitespace-nowrap">
                    <Badge style={{ backgroundColor: contract.status.color ?? "hsl(var(--background))" }}>
                      {contract.status.name}
                    </Badge>
                    {contractPolicy.view() ? (
                      <Link to={`${routes.contracts}/${contract.id}`}>
                        <p className="m-0 p-0 h-fit text-lg font-semibold">{contract.identifier}</p>
                      </Link>
                    ) : (
                      <span className="font-semibold text-lg">{contract.identifier}</span>
                    )}
                    <div className="flex flex-wrap gap-3 flex-row items-center">
                      {contract.products.map((product) => (
                        <Badge
                          key={product.id}
                          className="h-8 rounded-md"
                          style={{ backgroundColor: product.color ?? "hsl(var(--background))" }}
                        >
                          {product.name}
                        </Badge>
                      ))}
                    </div>
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
      </CardContent>
    </Card>
  );
};
