import { Button } from "@/components/Button";
import { ErrorMessage } from "@/components/forms/ErrorMessage";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import i18n from "@/i18n";
import { Plus, X } from "lucide-react";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

export const FormCalculatorAdditionalOptionsField = ({ name, label, className }) => {
  const {
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useFormContext();
  const emptyOption = { name: "", value: "", default: false };

  const options = watch(name) || [];

  const addOption = () => {
    const currentOptions = getValues(name) || [];
    setValue(name, [...currentOptions, emptyOption]);
  };

  const removeOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setValue(name, updatedOptions.length > 0 ? updatedOptions : []);
  };

  const handleOptionChange = (index, field, value) => {
    const updatedOptions = [...options];
    updatedOptions[index][field] = value;

    setValue(name, updatedOptions);
  };

  useEffect(() => {
    if (!options || options.length === 0) {
      setValue(name, []);
    }
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <div className={className}>
          <Label htmlFor={name} className={errors[name] && "text-destructive"}>
            {i18n.t(label)}
          </Label>
          <div className="space-y-2 mt-1">
            {options.map((option, index) => (
              <div key={index} className="flex gap-2 items-center">
                <Input
                  placeholder={i18n.t("Nazwa")}
                  value={option.name}
                  onChange={(e) => handleOptionChange(index, "name", e.target.value)}
                />
                <Input
                  placeholder={i18n.t("Wartość")}
                  type="number"
                  inputMode="numeric"
                  value={option.value}
                  onChange={(e) => handleOptionChange(index, "value", e.target.value)}
                />
                {options.length >= 1 && (
                  <Button
                    type="button"
                    variant="ghost"
                    className="text-destructive px-2"
                    leftIcon={<X size={16} />}
                    onClick={() => removeOption(index)}
                  />
                )}
              </div>
            ))}
          </div>
          <Button
            type="button"
            variant="outline"
            onClick={addOption}
            className="m-0 mt-3 pl-2 gap-1"
            leftIcon={<Plus size={16} />}
            title="Dodaj nową pozycję"
          />
          <ErrorMessage error={errors[name]?.message} />
        </div>
      )}
    />
  );
};
