import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { CalendarView } from "@/components/CalendarView";
import { SelectFilter } from "@/components/Filters/SelectFilter";
import { Layout } from "@/components/layout/Layout";
import { UsersContext } from "@/context/UsersContext";
import { getCalendarEvents } from "@/helpers/calendar/getCalendarEvents";
import { useAuth } from "@/hooks/useAuth";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { useQuery } from "@tanstack/react-query";
import { XCircle } from "lucide-react";
import { useContext, useEffect, useState } from "react";

export const CalendarPage = () => {
  useDocumentTitle("Kalendarz");
  const { hasPermission, user: authUser } = useAuth();
  const [filters, setFilters] = useState({ user: authUser.id });
  const [events, setEvents] = useState([]);
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);
  const [filteredUserOptions, setFilteredUserOptions] = useState([]);
  const userPolicy = useUserPolicy();

  const {
    isLoading,
    data: calendar,
    error,
  } = useQuery({
    queryKey: ["calendar", filters],
    queryFn: () => apiClient.getCalendar(filters),
    staleTime: 1000 * 60 * 5,
    enabled: hasPermission("access_calendar"),
  });

  useEffect(() => {
    if (!isLoadingUsers && userOptions) {
      setFilteredUserOptions(
        userOptions.map((userOption) => {
          if (userOption.value === authUser.id) {
            userOption.name = "Mój kalendarz";
          }
          return userOption;
        }),
      );
    }
  }, [isLoadingUsers, userOptions]);

  useEffect(() => {
    setEvents(() => []);
    if (!isLoading && calendar && !error) {
      setEvents(getCalendarEvents(calendar.data));
    }
  }, [isLoading, calendar, error]);

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col justify-between items-center sm:flex-row">
        <Breadcrumbs />
      </div>
      {hasPermission("access_calendar") && calendar && (
        <CalendarView
          events={events}
          Filters={
            userPolicy.viewCalendar() && (
              <>
                {filters.user && filters.user !== authUser.id && (
                  <Button
                    variant="outline"
                    leftIcon={<XCircle size={20} />}
                    size="icon"
                    onClick={() => setFilters({ user: authUser.id })}
                  />
                )}
                <div className="w-fit">
                  <SelectFilter
                    title="Użytkownik"
                    isLoading={isLoadingUsers}
                    options={filteredUserOptions}
                    filters={filters}
                    value={filters.user}
                    setValue={(value) => setFilters({ user: value })}
                    placeholder="Użytkownik"
                    name="user"
                  />
                </div>
              </>
            )
          }
        />
      )}
    </Layout>
  );
};
