import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { FileUploadInput } from "@/components/forms/FileUploadInput";
import { Text } from "@/components/Text";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { config } from "@/config";
import { useProductPolicy } from "@/policies/useProductPolicy";
import { routes } from "@/routes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Download, FileInput, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const ProductImportForm = () => {
  const productPolicy = useProductPolicy();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const csvURL = config("app.url") + "/produkty.csv";

  const [files, setFiles] = useState(null);

  const importProductsMutation = useMutation({
    mutationFn: apiClient.importProducts,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
      if (res.ok) {
        setFiles([]);
        toast({
          title: (
            <SuccessToast title="Zlecenie importu zostało wysłane na serwer, otrzymasz powiadomienie gdy import się zakończy." />
          ),
        });
        return navigate(routes.products);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const handleSubmit = () => {
    if (files.length) {
      const data = new FormData();
      data.append("products", files[0]);
      importProductsMutation.mutate({ data });
    }
  };

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = csvURL;
    a.download = "produkty.csv";
    a.click();
  };

  useEffect(() => {
    if (!productPolicy.importProducts()) return navigate(-1);
  }, []);

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <Text className="font-semibold" translate="Instrukcja importu" />
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-5">
        <div className="flex flex-col gap-3">
          <Text className="font-semibold" translate="1. Pobierz plik referencyjny." />
          <Button
            leftIcon={<Download size={20} />}
            title="produkty.csv"
            className="w-fit"
            tooltip="Pobierz plik referencyjny"
            onClick={handleDownload}
          />
        </div>
        <div className="flex flex-col gap-3">
          <Text className="font-semibold" translate="2. Dostosuj swój plik tak aby pasował do pliku pobranego." />
          <div>
            <Text className="font-semibold" translate="Zwróć uwagę:" />
            <ul className="list-disc pl-8">
              <li>
                <Text translate="Nazwy kolumn (nagłówki np. nazwa, dostepny itd.) nie mogą mieć polskich znaków i muszą być zapisane tak jak w szablonie." />
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <Text className="font-semibold" translate="3. Wyślij plik do importu." />
          <div className="w-1/3 pl-3">
            <FileUploadInput multiple={false} onFilesSelect={setFiles} accept={{ ".csv": [] }} />
          </div>
        </div>
      </CardContent>
      <CardFooter className="gap-3 items-center border-t px-6 py-4">
        <Button
          title="Importuj"
          leftIcon={<FileInput size={20} />}
          onClick={handleSubmit}
          isLoading={importProductsMutation.isPending}
        />
        <Button
          title="Anuluj"
          type="button"
          leftIcon={<X size={20} />}
          onClick={() => navigate(routes.products)}
          variant="destructive"
        />
      </CardFooter>
    </Card>
  );
};
