import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { Layout } from "@/components/layout/Layout";
import { ProductsPotentialDemandTable } from "@/components/Product/ProductsPotentialDemandTable";
import { ProductsTable } from "@/components/Product/ProductsTable";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import i18n from "@/i18n";
import { useProductPolicy } from "@/policies/useProductPolicy";
import { routes } from "@/routes";
import { Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const ProductsPage = () => {
  useDocumentTitle("Produkty");
  const navigate = useNavigate();
  const { state } = useLocation();
  const productPolicy = useProductPolicy();
  const [activeTab, setActiveTab] = useState("table");

  const handleCreate = () => {
    return navigate(routes.productCreate);
  };

  useEffect(() => {
    if (state?.tab) {
      setActiveTab(state.tab);
    }
  }, [state]);

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {activeTab === "table" && productPolicy.create() && (
            <Button variant="outline" title="Dodaj produkt" leftIcon={<Plus size={20} />} onClick={handleCreate} />
          )}
          {/* {activeTab === "table" && productPolicy.importProducts() && (
            <Button
              title="Import CSV"
              variant="outline"
              leftIcon={<FileInput size={16} />}
              onClick={() => navigate(routes.productImport)}
            />
          )} */}
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value)}>
        {productPolicy.create() && (
          <TabsList className="mb-6">
            <TabsTrigger value="table">{i18n.t("Tabela")}</TabsTrigger>
            {productPolicy.create() && (
              <TabsTrigger value="potential_needed_quantities">{i18n.t("Potencjalne zapotrzebowania")}</TabsTrigger>
            )}
          </TabsList>
        )}
        <TabsContent value="table">
          <ProductsTable />
        </TabsContent>
        {productPolicy.create() && (
          <TabsContent value="potential_needed_quantities">
            <ProductsPotentialDemandTable />
          </TabsContent>
        )}
      </Tabs>
    </Layout>
  );
};
