import { FormCalculatorAdditionalOptionsField } from "@/components/forms/FormCalculatorAdditionalOptionsField";
import { FormCalculatorMultipleSelectField } from "@/components/forms/FormCalculatorMultipleSelectField";
import { FormCalculatorSelectField } from "@/components/forms/FormCalculatorSelectField";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormField } from "@/components/forms/FormField";
import { FormSelect } from "@/components/forms/FormSelect";
import { ProductsContext } from "@/context/ProductsContext";
import { CalculatorFieldType } from "@/enums/CalculatorFieldTypeEnum";
import { evaluateCondition } from "@/helpers/calculators/evaluateCondition";
import { cn } from "@/lib/utils";
import { useProductPolicy } from "@/policies/useProductPolicy";
import { useContext, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

export const CalculatorField = ({ field }) => {
  const { products, isLoading } = useContext(ProductsContext);
  const productPolicy = useProductPolicy();
  const { control, getValues, setValue } = useFormContext();
  const [isVisible, setIsVisible] = useState(field.visible);

  const watchedValues = field.dependencies.map((dependency) => {
    const { attribute, depends_on_field } = dependency;
    const attributeKey =
      attribute === "quantity" ? `${depends_on_field.identifier}_quantity` : depends_on_field.identifier;
    return useWatch({ control, name: attributeKey });
  });

  useEffect(() => {
    if (field.dependencies.length > 0) {
      const isFieldVisible = field.dependencies.every((dependency) => {
        const { condition, action } = dependency;
        const attribute = condition.attribute;
        let fieldValue;
        let meetsCondition = null;

        if (attribute === "quantity") {
          fieldValue = getValues(`${dependency.depends_on_field.identifier}_quantity`);
          meetsCondition = evaluateCondition(fieldValue, condition);
        } else if (attribute === "value") {
          fieldValue = getValues(dependency.depends_on_field.identifier);
          meetsCondition = evaluateCondition(fieldValue, condition);
        } else if (attribute === "has_value") {
          fieldValue = getValues(dependency.depends_on_field.identifier);
          if (fieldValue && fieldValue !== "null") meetsCondition = true;
          else meetsCondition = false;
        } else if (attribute === "doesnt_have_value") {
          fieldValue = getValues(dependency.depends_on_field.identifier);
          if (!fieldValue || fieldValue === "null") meetsCondition = true;
          else meetsCondition = false;
        }

        return action === "SHOW" ? meetsCondition : !meetsCondition;
      });

      setIsVisible((prev) => {
        if (prev !== isFieldVisible) {
          if (!isFieldVisible) {
            setValue(field.identifier, undefined);
            if (field.has_quantity) {
              setValue(`${field.identifier}_quantity`, undefined);
            }
          }
          return isFieldVisible;
        }
        return prev;
      });
    }
  }, [watchedValues]);

  const renderFieldQuantityField = (field) => {
    if (field.has_quantity)
      return (
        <FormField
          type="number"
          inputMode="numeric"
          name={field.identifier + "_quantity"}
          label={field.unit ? `Ilość (${field.unit})` : "Ilość"}
        />
      );
    else return null;
  };

  const getProductFieldOptions = (field) => {
    let filteredProducts = products ? products.filter((product) => product.type.id === field.product_type) : [];
    let options = filteredProducts.map((product) => {
      if (productPolicy.create()) {
        if (product.warehouses_count) {
          if (product.total_quantity !== null) {
            product.content = (
              <span className="flex flex-col gap-1 justify-center">
                <p className={cn("font-semibold", product.total_quantity === 0 && "text-destructive")}>
                  {`${product.name} (${product.total_quantity} szt. w magazynach)`}
                </p>
                <p className="text-xs text-muted-foreground">{product.description}</p>
              </span>
            );
          } else {
            product.content = (
              <span className="flex flex-col gap-1 justify-center">
                <p className="font-semibold">{`${product.name} (w magazynie zewnętrznym)`}</p>
                <p className="text-xs text-muted-foreground">{product.description}</p>
              </span>
            );
          }
        } else {
          product.content = (
            <span className="flex flex-col gap-1 justify-center">
              <p className="font-semibold text-destructive">{`${product.name} (nie dodany)`}</p>
              <p className="text-xs text-muted-foreground">{product.description}</p>
            </span>
          );
        }
      } else {
        product.content = (
          <span className="flex flex-col gap-1 justify-center">
            <p className="font-semibold">{product.name}</p>
            <p className="text-xs text-muted-foreground">{product.description}</p>
          </span>
        );
      }

      product.value = product.id;

      return product;
    });
    if (!field.required) {
      options = [{ name: "Brak", value: "null" }, ...options];
    }
    return options.map(({ name, value, content }) => ({ name, value, content }));
  };

  const renderFieldByType = (field) => {
    switch (field.type) {
      case CalculatorFieldType.LIST.value: {
        let options = field.field_options;
        if (!field.required) {
          options = [{ name: "Brak", value: "null" }, ...options];
        }
        return (
          <FormCalculatorSelectField
            options={options}
            defaultValue={options.find((option) => option.default)?.name ?? undefined}
            label={field.name}
            name={field.identifier}
          />
        );
      }
      case CalculatorFieldType.MULTIPLE.value: {
        let options = field.field_options;
        return (
          <FormCalculatorMultipleSelectField
            options={options}
            defaultValue={options.find((option) => option.default)?.name ?? undefined}
            label={field.name}
            name={field.identifier}
          />
        );
      }
      case CalculatorFieldType.ADDITIONAL.value: {
        return <FormCalculatorAdditionalOptionsField label={field.name} name={field.identifier} />;
      }
      case CalculatorFieldType.PRODUCT.value: {
        const options = getProductFieldOptions(field);
        return <FormCombobox isLoading={isLoading} options={options} label={field.name} name={field.identifier} />;
      }
      case CalculatorFieldType.NUMBER.value:
        return <FormField label={field.name} name={field.identifier} type="number" />;
      case CalculatorFieldType.VAT.value: {
        return <FormSelect options={field.field_options} label={field.name} name={field.identifier} />;
      }
      case CalculatorFieldType.COMISSION.value: {
        return <FormField label={field.name} name={field.identifier} type="number" />;
      }
      default:
        return null;
    }
  };

  const renderField = (field) => {
    const renderedField = renderFieldByType(field);
    const renderedQuantityField = renderFieldQuantityField(field);
    if (renderedField) {
      return isVisible ? (
        <div className="flex flex-row items-start gap-3">
          <div className="flex-1">{renderedField}</div>
          {renderedQuantityField && <div className="w-1/5">{renderedQuantityField}</div>}
        </div>
      ) : null;
    }
  };

  return renderField(field);
};
