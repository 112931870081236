import { FileUploadInput } from "@/components/forms/FileUploadInput";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { FileCategoryContext } from "@/context/FileCategoryContext";
import { Settings } from "@/enums/SettingsEnum";
import { useSettings } from "@/hooks/useSettings";
import i18n from "@/i18n";
import { Asterisk } from "lucide-react";
import { useContext } from "react";

export const ContractFilesCreateForm = ({ files, setFiles }) => {
  const { getSetting } = useSettings();
  const { categories } = useContext(FileCategoryContext);
  const requiredContractFiles = getSetting(Settings.CONTRACT_FILE_REQUIRED_CATEGORIES);
  const optionalContractFiles = getSetting(Settings.CONTRACT_FILE_OPTIONAL_CATEGORIES);

  return (
    <Card className="h-fit">
      <CardHeader>
        <CardTitle className="text-base text-primary">{i18n.t("Pliki")}</CardTitle>
        <CardDescription>{i18n.t("Pliki z audytu zostaną dołączone automatycznie.")}</CardDescription>
      </CardHeader>
      <CardContent className="space-y-5">
        {requiredContractFiles.map((fileCategoryId, index) => (
          <div className="space-y-2" key={`contract_required_file_${index}`}>
            <Label htmlFor={fileCategoryId} className="flex flex-row items-center mb-1">
              {categories.find((category) => category.id === fileCategoryId)?.name}
              <Asterisk size={20} className="text-destructive" />
            </Label>
            <FileUploadInput id={fileCategoryId} onFilesSelect={(f) => setFiles({ ...files, [fileCategoryId]: f })} />
          </div>
        ))}
        {optionalContractFiles.map((fileCategoryId, index) => (
          <div className="space-y-2" key={`contract_optional_file_${index}`}>
            <Label htmlFor="contractFiles" className="flex flex-row items-center mb-1">
              {categories.find((category) => category.id === fileCategoryId)?.name}
            </Label>
            <FileUploadInput id={fileCategoryId} onFilesSelect={(f) => setFiles({ ...files, [fileCategoryId]: f })} />
          </div>
        ))}
      </CardContent>
    </Card>
  );
};
