import { InstallationStatusCreateDialog } from "@/components/Installations/Settings/Statuses/InstallationStatusCreateDialog";
import { InstallationStatuses } from "@/components/Installations/Settings/Statuses/InstallationStatuses";
import { ProcessCreateDialog } from "@/components/Process/ProcessCreateDialog";
import { ProcessSettings } from "@/components/Process/ProcessSettings";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import i18n from "@/i18n";
import { useInstallationStatusPolicy } from "@/policies/installation/useInstallationStatusPolicy";
import { useProcessPolicy } from "@/policies/useProcessPolicy";
import { useState } from "react";

export const InstallationSettings = () => {
  const [activeTab, setActiveTab] = useState("statuses");
  const installationStatusPolicy = useInstallationStatusPolicy();
  const processPolicy = useProcessPolicy();

  return (
    <Tabs value={activeTab} onValueChange={(value) => setActiveTab(value)}>
      <TabsList className="mb-6">
        {installationStatusPolicy.create() && <TabsTrigger value="statuses">{i18n.t("Statusy")}</TabsTrigger>}
        {processPolicy.create() && <TabsTrigger value="processes">{i18n.t("Procesy")}</TabsTrigger>}
      </TabsList>
      {installationStatusPolicy.create() && (
        <TabsContent value="statuses">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Statusy")}</h2>
            <InstallationStatusCreateDialog />
          </div>
          <InstallationStatuses />
        </TabsContent>
      )}
      {processPolicy.create() && (
        <TabsContent value="processes">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Procesy")}</h2>
            <ProcessCreateDialog appliesTo="Installation" />
          </div>
          <ProcessSettings model="Installation" />
        </TabsContent>
      )}
    </Tabs>
  );
};
