import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { ContractFilesCreateForm } from "@/components/Contracts/ContractFilesCreateForm";
import { Form } from "@/components/forms/Form";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormDatePicker } from "@/components/forms/FormDatePicker";
import { FormField } from "@/components/forms/FormField";
import { FormMultipleSelect } from "@/components/forms/FormMultipleSelect";
import { FormSelect } from "@/components/forms/FormSelect";
import { FormTextarea } from "@/components/forms/FormTextarea";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { ContractProductContext } from "@/context/ContractProductContext";
import { ContractStatusContext } from "@/context/ContractStatusContext";
import { FileCategoryContext } from "@/context/FileCategoryContext";
import { ContractPaymentType } from "@/enums/ContractPaymentTypeEnum";
import { Settings } from "@/enums/SettingsEnum";
import { getVoivodeshipOptions } from "@/helpers/address/getVoivodeshipOptions";
import { formatMoney } from "@/helpers/formatMoney";
import { useSettings } from "@/hooks/useSettings";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { routes } from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import { Check, X } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  first_name: Yup.string().required(i18n.t("Pole jest wymagane")),
  last_name: Yup.string().required(i18n.t("Pole jest wymagane")),
  phone_number: Yup.string()
    .required(i18n.t("Pole jest wymagane"))
    .min(9, "Numer telefonu musi mieć co najmniej 9 cyfr")
    .max(15, "Numer telefonu może mieć maksymalnie 15 cyfr"),
  email: Yup.string().email(i18n.t("To pole musi być poprawnym adresem email.")).required(i18n.t("Pole jest wymagane")),
  street_number: Yup.string().required(i18n.t("Pole jest wymagane")),
  postal_code: Yup.string().required(i18n.t("Pole jest wymagane")),
  city: Yup.string().required(i18n.t("Pole jest wymagane")),
  voivodeship: Yup.string().required(i18n.t("Pole jest wymagane")),
  contract_status_id: Yup.string().required(i18n.t("Pole jest wymagane")),
  personal_identity_number: Yup.string().required(i18n.t("Pole jest wymagane")),
  identifier: Yup.string().required(i18n.t("Pole jest wymagane")),
  contract_product_ids: Yup.array()
    .of(Yup.string())
    .required(i18n.t("Pole jest wymagane"))
    .min(1, i18n.t("Pole jest wymagane")),
  tranches: Yup.array().of(Yup.number()).required(i18n.t("Pole jest wymagane")),
  description: Yup.string().optional().nullable(),
  payment_type: Yup.string().required(i18n.t("Pole jest wymagane")),
  calculation_id: Yup.string().required(i18n.t("Pole jest wymagane")),
  signed_at: Yup.date(i18n.t("Pole musi być poprawną datą.")).required(i18n.t("Pole jest wymagane")),
});

export const ContractCreateForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const queryClient = useQueryClient();
  const { statusOptions, isLoading } = useContext(ContractStatusContext);
  const { productOptions, isLoading: isLoadingProducts } = useContext(ContractProductContext);
  const { getSetting } = useSettings();
  const { categories } = useContext(FileCategoryContext);

  const [files, setFiles] = useState({});
  const lead = state?.lead;
  const [calculationOptions, setCalculationOptions] = useState([]);
  const { voivodeshipOptions } = getVoivodeshipOptions();

  const defaultValues = {
    first_name: lead?.first_name,
    last_name: lead?.last_name,
    phone_number: lead?.phone_number,
    email: lead?.email,
    street_number: lead?.address?.street_number ?? undefined,
    postal_code: lead?.address?.postal_code ?? undefined,
    city: lead?.address?.city ?? undefined,
    voivodeship: lead?.address?.voivodeship ?? undefined,
    contract_status_id: statusOptions[0]?.value,
    contract_product_ids: [],
    personal_identity_number: undefined,
    identifier: undefined,
    signed_at: new Date(),
    tranches: [],
    tranche_count: "1",
    payment_type: undefined,
    calculation_id: undefined,
    description: undefined,
  };

  const onSubmit = async (data) => {
    const requiredFileCategoryIds = getSetting(Settings.CONTRACT_FILE_REQUIRED_CATEGORIES, []);

    for (const categoryId of requiredFileCategoryIds) {
      if (!files[categoryId] || files[categoryId].length === 0) {
        const category = categories.find((category) => category.id === categoryId);
        toast({ title: <ErrorToast title={`Pliki dla kategorii ${category.name} muszą być dodane.`} /> });
        return;
      }
    }

    try {
      data.lead_id = lead.id;

      const contractResponse = await createContractMutation.mutateAsync({ data });
      const contractId = contractResponse.data.id;

      for (const fileCategoryId of Object.keys(files)) {
        const filesToSend = files[fileCategoryId];
        if (!filesToSend.length) continue;

        const formData = new FormData();
        filesToSend.forEach((file, i) => {
          formData.append(`files[${i}]`, file);
        });
        formData.append("file_category_id", fileCategoryId);

        await uploadContractFilesMutation.mutateAsync({ contractId, data: formData });
      }

      setFiles({});
      toast({ title: <SuccessToast title="Umowa dodana" /> });
      queryClient.invalidateQueries({ queryKey: ["contracts"] });
      queryClient.invalidateQueries({ queryKey: ["sidebar"] });
      queryClient.invalidateQueries({ queryKey: ["products"] });
      navigate(`${routes.contracts}/${contractId}`);
    } catch (error) {
      if (error?.response?.status === 422) {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    }
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const watch = form.watch();

  const {
    isLoading: isLoadingCalculations,
    data: calculations,
    error: errorCalculations,
  } = useQuery({
    queryKey: ["lead", lead.id, "calculations"],
    queryFn: () => apiClient.getLeadCalculations({ leadId: lead?.id }),
    staleTime: 1000 * 60 * 5,
  });

  const createContractMutation = useMutation({
    mutationFn: apiClient.createContract,
  });

  const uploadContractFilesMutation = useMutation({
    mutationFn: apiClient.createContractFiles,
  });

  const getTrancheSum = () => {
    return watch.tranches.reduce((sum, tranche) => sum + parseFloat(tranche ?? 0), 0);
  };

  useEffect(() => {
    if (!isLoadingCalculations && calculations && !errorCalculations) {
      setCalculationOptions(
        calculations.data.map((calculation) => ({
          name:
            calculation.calculator.name +
            ` (${formatMoney(calculation.gross_price)} brutto)` +
            ` ${format(parse(calculation.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd LLL yyyy HH:mm")}`,
          value: calculation.id,
        })),
      );
    }
  }, [calculations, errorCalculations, isLoadingCalculations]);

  useEffect(() => {
    if (watch.calculation_id) {
      const calculation = calculations.data.find(({ id }) => id === watch.calculation_id);
      form.setValue("net_price", calculation.net_price);
      form.setValue("gross_price", calculation.gross_price);
      form.setValue("vat", calculation.vat);
      form.setValue("comission", calculation.comission);
    }
  }, [watch.calculation_id]);

  useEffect(() => {
    form.resetField("tranches");
    form.resetField("down_payment");
  }, [watch.payment_type]);

  useEffect(() => {
    if (watch.gross_price) {
      if (watch.payment_type === ContractPaymentType.CASH.value) {
        if (watch.tranche_count === "1") {
          form.resetField("tranches");
          form.setValue("tranches[0]", watch.gross_price);
        } else if (watch.tranche_count === "2") {
          const first = Math.floor(watch.gross_price / 2);
          const second = parseFloat(watch.gross_price - first).toFixed(2);
          form.resetField("tranches");
          form.setValue("tranches[0]", first);
          form.setValue("tranches[1]", second);
        } else if (watch.tranche_count === "3") {
          const firstSecond = Math.floor(watch.gross_price / 3);
          const third = parseFloat(watch.gross_price - firstSecond * 2).toFixed(2);
          form.resetField("tranches");
          form.setValue("tranches[0]", firstSecond);
          form.setValue("tranches[1]", firstSecond);
          form.setValue("tranches[2]", third);
        }
      }
    }
  }, [watch.payment_type, watch.tranche_count, watch.gross_price]);

  useEffect(() => {
    if (!lead) return navigate(-1);
  }, []);

  return (
    <Form form={form} onSubmit={onSubmit}>
      <div className="flex flex-col xl:flex-row w-full gap-5 mt-5 ">
        <Card className="flex-1 h-fit">
          <CardHeader>
            <CardTitle className="text-base text-primary">{i18n.t("Informacje")}</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col gap-4">
              <FormField name="first_name" label="Imię" autoComplete="first_name" />
              <FormField name="last_name" label="Nazwisko" autoComplete="last_name" />
              <FormField name="email" type="email" autoComplete="email" label="Email" />
              <FormField name="phone_number" autoComplete="phone_number" label="Numer telefonu" inputMode="numeric" />
              <FormSelect label="Status" options={statusOptions} name="contract_status_id" isLoading={isLoading} />
              <FormField name="street_number" label="Ulica i numer" />
              <div className="flex flex-row gap-3 items-center">
                <FormField name="postal_code" label="Kod pocztowy" />
                <FormField name="city" label="Miasto" />
              </div>
              <FormCombobox options={voivodeshipOptions} name="voivodeship" label="Województwo" required={false} />
              <FormField name="personal_identity_number" label="PESEL" />
              <FormField name="identifier" label="Numer umowy" />
              <FormDatePicker name="signed_at" label="Data podpisania umowy" />
              <FormMultipleSelect
                isLoading={isLoadingProducts}
                options={productOptions}
                name="contract_product_ids"
                label="Produkty"
              />
              <FormSelect options={calculationOptions} label="Kalkulacja" name="calculation_id" />
              <FormTextarea name="description" label="Opis" placeholder="Wszystkie niestandardowe ustalenia" />
            </div>
          </CardContent>
          <CardFooter className="gap-3 items-center border-t px-6 py-4">
            <Button
              type="submit"
              title="Zapisz"
              leftIcon={<Check size={20} />}
              isLoading={createContractMutation.isPending}
            />
            <Button
              title="Anuluj"
              type="button"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => navigate(-1)}
            />
          </CardFooter>
        </Card>
        <div className="flex flex-1 gap-5 flex-col">
          <Card>
            <CardHeader>
              <CardTitle className="text-base text-primary">{i18n.t("Informacje o płatności")}</CardTitle>
            </CardHeader>
            <CardContent className="space-y-5">
              <FormField name="net_price" disabled={true} label="Wartość netto" type="number" />
              <FormField disabled={true} name="vat" label="VAT" type="number" />
              <FormField name="gross_price" disabled={true} label="Wartość brutto" type="number" />
              <FormField name="comission" disabled={true} label="Prowizja" type="number" />
              <FormSelect label="Typ płatności" options={ContractPaymentType.getValues()} name="payment_type" />
              {[ContractPaymentType.LOAN.value, ContractPaymentType.PREFINANCED.value].includes(watch.payment_type) && (
                <FormField type="number" step=".01" name="down_payment" label="Wpłata własna klienta" />
              )}
              {watch.payment_type === ContractPaymentType.CASH.value && (
                <>
                  <FormSelect
                    options={[
                      { name: "Jedna", value: "1" },
                      { name: "Dwie", value: "2" },
                      { name: "Trzy", value: "3" },
                    ]}
                    defaultOption={{ name: "Jedna", value: "1" }}
                    name="tranche_count"
                    label="Liczba transz płatności"
                  />
                  {watch.tranche_count > 0 &&
                    Array.from({ length: parseInt(watch.tranche_count) }, (_, i) => (
                      <FormField
                        key={`tranche_${i + 1}`}
                        type="number"
                        name={`tranches[${i}]`}
                        label={`Transza ${i + 1}`}
                        placeholder={`Wartość transzy ${i + 1} w zł`}
                      />
                    ))}
                  <span
                    className={cn(
                      "flex flex-row gap-1",
                      getTrancheSum() === parseFloat(watch.gross_price) ? "text-green-500" : "text-red-500",
                    )}
                  >
                    <p>{i18n.t("Suma transz")}</p>
                    <p>{formatMoney(getTrancheSum())}</p>
                  </span>
                </>
              )}
            </CardContent>
          </Card>
          <ContractFilesCreateForm files={files} setFiles={setFiles} />
        </div>
      </div>
    </Form>
  );
};
